import React, { Component } from 'react'

export default class About extends Component {
    render() {
      return (
        <section className="about" id="about">
                <div className="container">
                    <div className="row bottom_90">
                        <div className="col-lg-5 image wow fadeInUp" data-wow-delay="0.4s">
                            <img src="img/about-us.jpg" alt=""/>
                        </div>
                        <div className="col-lg-6 offset-lg-1 text wow fadeInUp" data-wow-delay="0.8s">
                            <div className="section-title bottom_15 top_45">
                                <span>ABOUT US</span>
                                <h2 className="title">We are working on web development, <br/> software development and mobile applications.</h2>
                            </div>
                            <p>We can take care of all your web needs and provide end-to-end web solutions that are highly customized.
                            We are well known for our web development, Web application development, highly effective website. we make sure that our solutions are delivered to clients on time, every time.<br/></p>
                            <p> Our team of programmers is highly knowledgeable in the latest technologies and has years of experience in the software industry.</p>
                        </div>                
                    </div>
                    <div className="clients bottom_120 row wow fadeInLeft" data-wow-delay="1.0s" data-wow-offset="-100">
                        <div className="col-12 col-sm-6 col-lg">
                            <img src="img/clients/jmb-malaysia.png" alt=""/>
                        </div>
                        <div className="col-12 col-sm-6 col-lg">
                            <img src="img/clients/purbly.png" alt=""/>
                        </div>
                        <div className="col-12 col-sm-6 col-lg">
                            <img src="img/clients/tukang.png" alt=""/>
                        </div>
                        <div className="col-12 col-sm-6 col-lg">
                            <img src="img/clients/4.jpg" alt=""/>
                        </div>
                        <div className="col-12 col-sm-6 col-lg">
                            <img src="img/clients/5.jpg" alt=""/>
                        </div>
                    </div>
                </div>
        </section>
    )
    }
}
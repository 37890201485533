import React, { Component } from 'react'

export default class Header extends Component {
    render() {
      return (
        <header>
            <div className="container">
                <a href="#" className="logo">
                    <img src="img/logo.png" style={{height: '50px'}} alt="Susoft"/>
                </a>
                <div className="responsive"><i className="fas fa-bars"></i></div>    
                <nav>
                    <ul>
                        <li><a href="#home">Home</a></li>
                        <li><a href="#about">About</a></li>
                        <li><a href="#portfolio">Portfolio</a></li>
                        <li><a href="#services">Services</a></li>
                        {/* <li><a href="#blog">Blogs</a></li> */}
                        <li><a href="#contact">Contact</a></li>
                    </ul>
                </nav>
            </div>        
        </header>
      )
    }
  }
import React, { Component } from 'react'
import ContactForm from '../ContactForm'

export default class Portfolio extends Component {
    render() {
      return (
        <section className="contact" id="contact">
            <div className="container">
                <div className="section-title bottom_60 text-center">
                    <span>CONTACT US</span>
                    <h2 className="title">Get In Touch</h2>
                </div>
                <div className="information col-lg-8 offset-lg-2">
                    <div className="row">
                        <div className="col-md-4 col-sm-6 info text-center wow fadeInUp" data-wow-delay="0.3s">
                            <i className="icon-phone"></i>
                            <p>84 985 05 48 04</p>
                        </div>
                        <div className="col-md-4 col-sm-6 info text-center wow fadeInUp" data-wow-delay="0.6s">
                            <i className="icon-map"></i>
                            <p>Binh Chanh District,<br/> Ho Chi Minh City, Viet Nam</p>
                        </div>
                        <div className="col-md-4 col-sm-12 info text-center wow fadeInUp" data-wow-delay="0.9s">
                            <i className="icon-envelope"></i>
                            <p>info@susoft.top</p>
                        </div>
                    </div>
                </div>
                <ContactForm/>        
            </div>
        </section>
      )
    }
}
import React, { Component } from 'react'

export default class Home extends Component {
    render() {
      return (
      <section className="home" id="home">
        <div className="home-content">
            <div className="container">
                <span className="hi">Hi There,</span>
                <h1 className="title">We Are <span className="element" data-text1="Creative" data-text2="Susoft" data-loop="true" data-backdelay="3000">Susoft</span></h1>
                <p>We Turn Your Idea Into Reality. <br/> We Use The Newest Technologies And Achieve Super Results.</p>
                <div className="social top_30">
                    <a href="#"><i className="fab fa-twitter" aria-hidden="true"></i>  </a>
                    <a href="#"><i className="fab fa-instagram" aria-hidden="true"></i>  </a>
                    <a href="#"><i className="fab fa-behance" aria-hidden="true"></i>  </a>
                    <a href="#"><i className="fab fa-dribbble" aria-hidden="true"></i>  </a>
                </div>
                <a href="#about" className="down-icon wow fadeInUp" data-wow-delay="2.0s">
                    <div className="icon-circle" style={{transform: 'matrix(1, 0, 0, 1, 0, 0)'}}></div>
                    <div className="icon" style={{transform: 'matrix(1, 0, 0, 1, 0, 0)'}}>
                        <i className="fas fa-angle-down"></i>
                    </div>
                </a>
			</div>
        </div>    
    </section>
    )
    }
}
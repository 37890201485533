import React, { Component } from 'react'

export default class Portfolio extends Component {
    render() {
      return (
        <section className="portfolio gray-bg" id="portfolio">
        <div className="container">
            <div className="section-title bottom_60 text-center">
    					<span>Gain a Success With Us!</span>
    					<h2 className="title">Solid Solutions &amp; Perfect Achievement.</h2>
                        <div className="portfolio_filter top_30 bottom_60">
                            <ul>
                                <li className="select-cat" data-filter="*">All Works</li>
                                <li data-filter=".web-development">Web Development</li>
                                <li data-filter=".aplication">Mobile Applications</li>
                                <li data-filter=".development">Software Development</li>
                            </ul>
                        </div>
				</div>
             <div className="isotope_items row">
                    <a data-tilt data-tilt-perspective="2000" href="img/my-strata.png" className="single_item link web-development col-md-4 col-sm-6 wow fadeInUp" data-wow-delay="0.3s">
                        <img src="img/my-strata.png" alt=""/> 
                    </a>
                    <a data-tilt data-tilt-perspective="2000" href="https://www.youtube.com/watch?v=rjrOABCS6oY" className="popup-youtube single_item link aplication col-md-4 col-sm-6 wow fadeInUp" data-wow-delay="1.8s">
                        <img src="img/purbly-app-1.png" alt=""/> 
                    </a>
                    <a data-tilt data-tilt-perspective="2000" href="img/purbly-task-web-1.png" className="single_item link web-development col-md-4 col-sm-6 wow fadeInUp" data-wow-delay="1.2s">
                        <img src="img/purbly-task-web-1.png" alt=""/> 
                    </a>
                    <a data-tilt data-tilt-perspective="2000" href="img/landlords3.0.png" className="single_item link aplication col-md-4 col-sm-6 wow fadeInUp" data-wow-delay="1.5s">
                        <img src="img/landlords3.0.png" alt=""/> 
                    </a>
                    <a data-tilt data-tilt-perspective="2000" href="img/tukang-homepage-apps.png" className="single_item link aplication col-md-4 col-sm-6 wow fadeInUp" data-wow-delay="0.6s">
                        <img src="img/tukang-homepage-apps.png" alt=""/> 
                    </a>
                    <a data-tilt data-tilt-perspective="2000" href="img/check-in-app.png" className="single_item link development col-md-4 col-sm-6 wow fadeInUp" data-wow-delay="0.9s">
                        <img src="img/check-in-app.png" alt=""/> 
                    </a>
                </div>
        </div>
    </section>
    )
    }
}
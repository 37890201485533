import React, { Component } from 'react'

export default class Footer extends Component {
    render() {
      return (
        <footer className="homef">
            <div className="cont text-center padding_90">
                <p>Copyright © 2015 SuSoft, All rights Reserved.</p>
                <div className="social top_30">
                    <a href="#"><i className="fab fa-facebook"></i>  </a>
                    <a href="#"><i className="fab fa-twitter" aria-hidden="true"></i>  </a>
                    <a href="#"><i className="fab fa-instagram" aria-hidden="true"></i>  </a>
                    <a href="#"><i className="fab fa-behance" aria-hidden="true"></i>  </a>
                    <a href="#"><i className="fab fa-dribbble" aria-hidden="true"></i>  </a>
                </div>
            </div>
        </footer>
      )
    }
  }
import React, { Component } from 'react'
const axios = require('axios');

export default class ContactForm extends Component {
    constructor(props) {
      super(props);
      this.state = {
        name: '',
        email: '',
        message: ''
      }
  
      this.handleSubmit = this.handleSubmit.bind(this);
    }
  
    handleSubmit(event) {
      event.preventDefault();
      console.log(this.state);
        axios.post('https://id.susoft.top/api/contactUs', this.state)
        .then(function (response) {
            alert(response.data.message);
        })
        .catch(function (error) {
          console.log(error);
        });
        this.resetForm()
    }

    resetForm(){
      this.setState({name: '', email: '', message: ''})
    }
  
    render() {
      return (
        <form onSubmit={this.handleSubmit.bind(this)} className="contact-form top_90 col-md-12 padding_90 wow fadeInUp" data-wow-delay="1.3s">
        <div className="col-md-8 offset-md-2">
            <div className="row">
                <div className="col-md-6">
                    <input required id="con_name" name="con_name" className="form-inp requie" type="text" placeholder="Name" value={this.state.name} onChange={this.onNameChange.bind(this)}/>
                </div>
                <div className="col-md-6">
                    <input required id="con_email" name="con_email" className="form-inp requie" type="text" placeholder="Email" value={this.state.email} onChange={this.onEmailChange.bind(this)}/>
                </div>
                <div className="col-md-12">
                    <textarea required name="con_message" id="con_message" className="requie" placeholder="How can we help you?" rows="8" value={this.state.message} onChange={this.onMessageChange.bind(this)} />
                    <button id="con_submit" className="site-btn top_30 pull-right" type="submit">Send a Message</button>
                </div>
            </div>
        </div>
    </form>
      );
    }

    onNameChange(event) {
      this.setState({name: event.target.value})
    }
  
    onEmailChange(event) {
      this.setState({email: event.target.value})
    }
  
    onMessageChange(event) {
      this.setState({message: event.target.value})
    }
  }
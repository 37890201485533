import './App.css';
import Loader from './components/Loader'
import Header from './components/Header'
import Home from './components/Home'
import About from './components/About'
import Portfolio from './components/Portfolio'
import Services from './components/Services'
// import Blog from './components/Blog'
import Contact from './components/Contact'
import Footer from './components/Footer'

function App() {
  return (
    <div>
      <Loader></Loader>
      <Header></Header>
      <Home></Home>
      <About></About>
      <Portfolio></Portfolio>
      <Services></Services>
      {/* <Blog></Blog> */}
      <Contact></Contact>
      <Footer></Footer>
    </div>
  );
}

export default App;

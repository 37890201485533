import React, { Component } from 'react'

export default class Services extends Component {
    render() {
      return (
        <section className="whatwedo padding_90" id="services">
        <div className="container">
            <div className="section-title bottom_60 text-center">
                <span>WHAT WE DO</span>
                <h2 className="title">We’re offering services</h2>
            </div>
            <div className="row">
                <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
                    <div className="service">
                        <i className="icon-browser"></i>
                        <h3 className="title">Web Development</h3>
                        <p>We provide a solution for single page web application that's responsive and mobile friendly. We've integrated the latest technology to make sure it's stable and scalable.</p>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="1.5s">
                    <div className="service">
                        <i className="icon-phone"></i>
                        <h3 className="title">Mobile App</h3>
                        <p>Our technical experts build custom apps with your business goals in mind. We create cross-platform solutions that work seamlessly on any device mobile.</p>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.6s">
                    <div className="service">
                        <i className="icon-desktop"></i>
                        <h3 className="title">Software Development</h3>
                        <p>For new software product creation, our teams use agile and continuous delivery practices to accelerate development. We provide high quality and cost effective services.</p>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.9s">
                    <div className="service">
                        <i className="icon-paintbrush"></i>
                        <h3 className="title">Free Simple Website</h3>
                        <p>We provide webpage designs and build your website and grow your ideal business. We will build a free website to help you get discovered and grow your customer.</p>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="1.2s">
                    <div className="service">
                        <i className="icon-profile-male"></i>
                        <h3 className="title">Dedicated Development Team</h3>
                        <p>Scale your delivery capacity with intelligent dedicated developers. A dedicated, customized, and follows our client's internal development practices, methodologies, and culture.</p>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="1.8s">
                    <div className="service">
                        <i className="icon-toolbox"></i>
                        <h3 className="title">Digital Transformation Sevices</h3>
                        <p>Our development teams already have the expertise, platforms, and skills to deliver superior IT transformation services tailored to stay ahead of evolving market demands.</p>
                    </div>
                </div>
            </div>
        </div>
    </section>
    )
    }
}